export enum Role {
  Readers = 0,
  Customer = 1,
  Controllers = 2,
  Administrators = 3,
}
export enum AADRole {
  "d8e1f8f5-59b1-4cbf-8d25-0e2e5725cb85" = 0, // RBAC_ovrig-anställd
  "3524073e-c370-40c9-a2dc-6907fef27625" = 1, // RBAC_energi_controller
  //   'customer' = 0,
  //   'RBAC_ovrig-anställd' = 1,
  //   'RBAC_energi_controller' = 2,
  //   'RBAC_forvaltare' = 3,
  //   'RBAC_platschef' = 4,
  //   'RBAC_kundansvarig' = 5,
  //   'RBAC_Front-office' = 6,
}
export interface Auth {
  id: number;
  isLoggedIn: boolean;
  refreshToken: string;
  expires: Date;
  role: string;
  token: string;
  userName: string;
  isAAD?: boolean;
}
export interface Login {
  username: string;
  password: string;
}

export interface LoginState {
  auth: Auth | null;
  status: number | undefined;
  isLoading: boolean;
  errorMessage: string;
}
export interface Estate {
    id: number
    ownerId: number
    name: string
    description: string
    updDate: Date
    insDate: Date
    hidden: boolean
    addresses: Address[]
}
export interface Address {
    id: number
    estateId: number
    name: string
    street: string
    postalCode: string
    city: string
    number: string
    comment: string
    hidden: boolean
    updDate: Date
    insDate: Date
    measurePoints: MeasurePoint[]
    allMeasurePointsHaveReadings: boolean
}
export interface MeasurePoint {
    id: number
    addressId: number
    name: string
    include: number
    description: string
    hidden: boolean
    updDate: Date
    insDate: Date
    number?: string | undefined,
    inactivePeriods?: number[]
    counters: Counter[]
    type: string
    measurePointNumbers: string[]
    allCountersHaveReadings: boolean
}
export interface Counter {
    id: number
    measurePointId: number
    meterId: number
    name: string
    turned: boolean
    constant: number
    hidden: boolean
    insDate: Date
    updDate: Date
    counterNumbers: string[]
    hasReadingThisPeriod: boolean
    lastReading: number
    lastReadingDate: Date
    estimatedReading: number
    isConsumption: boolean
    isHeatExchanger: boolean    
    tags: Tag[]
}
export interface Tag {
  id: number
  name: string
  class: string
}
export interface CounterResetParameter {
  turned: boolean,
  changed: boolean
}
export interface CounterValueParameter {
  counterId: number,
  date: Date,
  value: number,
  override: boolean,
  counterReset: CounterResetParameter
}