import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store";
import {
  Container,
  Typography,
  Select,
  MenuItem,
  Button,
  TextField,
  Box,
  List,
  ListItem,
  ListItemText,
  Alert,
  ListItemIcon,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import saveupService from "../api/saveupService";
import { fetchEstates, searchEstates } from "../redux/estateSlice";
import { Estate } from "../interfaces/interfaces";
import "./style/custom.css";
import { Search, Home } from "@mui/icons-material";

const Estates: React.FC = () => {
  const { user } = useSelector((state: RootState) => state.auth);
  const estates: Estate[] = useSelector(
    (state: RootState) => state.estates.fetchEstates.data
  );
  const searchResult: Estate[] = useSelector(
    (state: RootState) => state.estates.searchEstates.data
  );
  const [selectedEstate, setSelectedEstate] = useState<Estate | undefined>(
    undefined
  );
  const [keyword, setKeyword] = useState<string>("");
  const [searchError, setSearchError] = useState<string | null>(null);
  const [isCustomerRole, setIsCustomerRole] = useState<boolean>(
    user ? user.role.toLowerCase() === "customer" : false
  );
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    dispatch(fetchEstates());
  }, []);

  const handleSearch = () => {
    if (keyword.length > 3) {
      dispatch(searchEstates(keyword));
    }
  };

  useEffect(() => {
    if (user) {
      setIsCustomerRole(user.role.toLowerCase() === "customer");
    }
  }, [user]);

  const handleSelectEstate = (estateId: number) => {
    const estateById = estates.find((estate) => estate.id === estateId);
    setSelectedEstate(estateById);
  };

  return (
    <div className="estate-container">
      <div className="estate-box">
        <Box display="flex" justifyContent="center" className="image-logo">
          <img src="img/saveup.logo.svg" alt="SaveUp Logo" className="logo" />
        </Box>
        <Typography variant="h5" gutterBottom>
          Mina fastigheter
        </Typography>
        <Box
          component="form"
          sx={{ display: "flex", flexDirection: "column", gap: 2 }}
        >
          <Select
            value={selectedEstate ? selectedEstate.id : ""}
            onChange={(e) => handleSelectEstate(Number(e.target.value))}
            fullWidth
          >
            {estates.map((estate) => (
              <MenuItem key={estate.id} value={estate.id}>
                <Home />
                &nbsp;{estate.name}
              </MenuItem>
            ))}
          </Select>
          {!isCustomerRole ? (
            <div>
              <TextField
                label="Sök fastighet..."
                variant="outlined"
                fullWidth
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
              />
              <Button
                variant="contained"
                fullWidth
                onClick={handleSearch}
                disabled={keyword.length <= 3}
                color="secondary"
                style={{ marginTop: "8px" }}
              >
                <Search />
                &nbsp;Sök
              </Button>
            </div>
          ) : (
            ""
          )}
          {searchError && <Alert severity="error">{searchError}</Alert>}
          {!isCustomerRole ? (
            <div>
              <List
                sx={{
                  display: searchResult.length > 0 ? "block" : "none",
                }}
              >
                {searchResult.map((estate) => (
                  <ListItem
                    key={estate.id}
                    onClick={() => handleSelectEstate(estate.id)}
                    sx={{
                      "&:hover": {
                        backgroundColor: "#f0f0f0",
                      },
                      backgroundColor:
                        selectedEstate && selectedEstate.id === estate.id
                          ? "#d0d0d0"
                          : "inherit",
                    }}
                  >
                    <ListItemIcon>
                      <Home />
                    </ListItemIcon>
                    <ListItemText primary={estate.name} />
                  </ListItem>
                ))}
              </List>
            </div>
          ) : (
            ""
          )}
          <Button
            variant="contained"
            className="button-primary"
            fullWidth
            disabled={!selectedEstate}
            onClick={() =>
              selectedEstate && navigate(`/estate/${selectedEstate.id}`)
            }
          >
            Nästa
          </Button>
        </Box>
      </div>
    </div>
  );
};

export default Estates;
