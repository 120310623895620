import { Navigate, Outlet } from "react-router-dom";
import { isAADAuthorized, isAuthorized, isTokenExpired } from "../utils/utils";

const PrivateRoutes = () => {
  return !isTokenExpired() && (isAuthorized() || isAADAuthorized()) ? (
    <Outlet />
  ) : (
    <Navigate to="/login" />
  );
};
export default PrivateRoutes;
