import jwt_decode from 'jwt-decode'
import { AADRole, Auth, Role } from '../interfaces/interfaces'
/**
 *  Verify token has not expired
 * @returns boolean
 */
export function isTokenExpired() {
    const authData: string | null = localStorage.getItem('auth')
    if (authData) {
      const auth: Auth = JSON.parse(authData ?? '')
      if (auth) {
        try {
          const decoded: any = jwt_decode(auth.token)
          const expirationTimeStamp: number = decoded.exp * 1000 - 60000
          const expirationDate = new Date(expirationTimeStamp)
          return new Date() >= expirationDate
        } catch (error) {}
      }
    }
    return false
  }
  /**
 * Verify user is authorized to access the application
 * @returns boolean
 */
export function isAuthorized() {
    const authData: string | null = localStorage.getItem('auth')
    if (authData) {
      const auth: Auth = JSON.parse(authData)
      if (auth) {
        try {
          const decoded: any = jwt_decode(auth.token)
          let role: Role = Role[decoded.role as keyof typeof Role]
          return role >= Role.Customer
        } catch (error) {}
      }
    }
    return false
  }
  /**
 * Is Authorized through Azure AD or SaveUp
 * @returns boolean
 */
export function isAADAuthorized() {
    const authData = localStorage.getItem('auth');
    if (authData) {
      const auth = JSON.parse(authData);
      if (auth && auth.token) {
        try {
          const decoded: any = jwt_decode(auth.token)
          // Get all number valued enums.
          const enumArray = Object.entries(AADRole).filter(([key, value]) => typeof value === 'number').map(([key, value]) => ({ name: key, value}))
  
          // Fetch the enums that matches a group in groups.
          const relevantRoles = enumArray.filter((enumValue) => decoded.groups.includes(enumValue.name))
          console.log(relevantRoles)
          // Find the enum with the highest value.
          const role = relevantRoles.reduce((prev, current) => (prev.value > current.value) ? prev : current).name
          
          // Cast role to enum of AADRole type
          let userRole: AADRole = AADRole[role as keyof typeof AADRole]
          console.log(userRole)
          return userRole >= AADRole['d8e1f8f5-59b1-4cbf-8d25-0e2e5725cb85']
        } catch (error) {
          console.error('Error decoding AAD token', error);
          return false;
        }
      }
    }
    return false;
  }
  