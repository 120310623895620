import React from 'react';
import './style/custom.css'; // or Layout.module.css if you prefer CSS modules
import './img/bg.svg';

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div className="layout-background">
      {children}
    </div>
  );
};

export default Layout;