import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Collapse,
  TextField,
  Button,
  Box,
  Alert,
  IconButton,
} from "@mui/material";
import { ExpandMore, ExpandLess, CheckCircle } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { useNavigate, useParams } from "react-router-dom";
import { fetchEstate } from "../redux/estateSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store";
import { SimpleTreeView, TreeItem } from "@mui/x-tree-view";
import { SubmitHandler, useForm } from "react-hook-form";
import { Electricity, ElectricityHeat, CoolingEnergy, CoolingFlow, HeatingEnergy, HeatingFlow, Time, Oil, Temperature, Water, Efficiency } from "./icons/gomorron";
import {Domain, House, PinDrop, Speed, ChevronRight, Check, Recycling} from '@mui/icons-material'
const useStyles = makeStyles({
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "1rem",
    marginTop: "2rem",
  },
  logo: {
    width: "150px",
    marginBottom: "1rem",
  },
  listGroup: {
    width: "100%",
  },
  listItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  multiValueWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  errorAir: {
    borderColor: "red",
  },
  childListItem: {
    paddingLeft: "1rem",
    borderLeft: "1px solid #ddd",
    marginBottom: "0.5rem",
  },
  grandChildListItem: {
    paddingLeft: "0.5rem",
    paddingBottom: "0.5rem",
    paddingRight: "0.5rem",
    border: "1px solid #ddd",
    marginBottom: "0.5rem",
  },
  errorTextField: {
    backgroundColor: "#ffcccc",
  },
});

interface Counter {
  id: string;
  name: string;
  counterNumbers: string[];
  isHeatExchanger: boolean;
  lastReading: number;
  lastReadingDate: string;
  hasReadingThisPeriod: boolean;
  estimatedReading: number;
  tags: string[];
}

interface MeasurePoint {
  id: string;
  name: string;
  measurePointNumbers: string[];
  type: string;
  allCountersHaveReadings: boolean;
  counters: Counter[];
}

interface Address {
  id: string;
  name: string;
  allMeasurePointsHaveReadings: boolean;
  measurePoints: MeasurePoint[];
}

interface Estate {
  addresses: Address[];
}

interface IFormInput {
  [key: string]: string;
}

const renderTypeIcon = (item: any, defaultValue = '') => {
  switch (item.type) {
    case 'ELECTRICITY':
      return <Electricity className='saveup-tree-icon' />
    case 'HEAT_EXCHANGER':
      return <Efficiency className='saveup-tree-icon' />
    case 'ELECTRICITY_HEAT':
      return <ElectricityHeat className='saveup-tree-icon' />
    case 'COOLING_ENERGY':
      return <CoolingEnergy className='saveup-tree-icon' />
    case 'COOLING_FLOW':
      return <CoolingFlow className='saveup-tree-icon' />
    case 'HEATING_ENERGY':
      return <HeatingEnergy className='saveup-tree-icon' />
    case 'HEATING_FLOW':
      return <HeatingFlow className='saveup-tree-icon' />
    case 'TIME':
      return <Time className='saveup-tree-icon' />
    case 'OIL':
      return <Oil className='saveup-tree-icon' />
    case 'WATER':
      return <Water className='saveup-tree-icon' />
    case 'TEMPERATURE':
      return <Temperature className='saveup-tree-icon' />
    case 'RECYCLING_ENERGY':
      return <Recycling />
    case 'RECYCLING_FLOW':
      return <Recycling />
    case 'OWNER':
      return <Domain />
    case 'ESTATE':
      return <House />
    case 'ADDRESS':
      return <PinDrop />
    case 'MEASUREPOINT':
      return <Speed />
    case 'COUNTER':
      return <Speed />
    default:
      return defaultValue
  }
}

const EstateView: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { estateId } = useParams<{ estateId: string }>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const estate = useSelector(
    (state: RootState) => state.estates.fetchEstate.data
  );

  const [airValues, setAirValues] = useState<{ [key: string]: number }>({});
  const [efficiency, setEfficiency] = useState<{ [key: string]: string }>({});
  const [disableSubmit, setDisableSubmit] = useState(false);
  const { register, handleSubmit } = useForm<IFormInput>();

  useEffect(() => {
    // Dispatch method to fetch estate by id.
    dispatch(fetchEstate(Number(estateId)));
  }, [estateId]);

  useEffect(() => {
    const calculateEfficiency = () => {
      const newEfficiency: { [key: string]: string } = {};
      let shouldDisableSubmit = false;

      Object.keys(airValues).forEach((key) => {
        const id = parseInt(key.split("_").pop() || "");

        const fromAir = airValues[`from_air_${id}`] || 0;
        const offAir = airValues[`off_air_${id}`] || 0;
        const outAir = airValues[`out_air_${id}`] || 0;

        if (fromAir && offAir && outAir) {
          let efficiencyValue = ((fromAir - offAir) / (fromAir - outAir)) * 100;
          if (isNaN(efficiencyValue) || !isFinite(efficiencyValue)) {
            efficiencyValue = 0;
          }
          newEfficiency[id] = `${efficiencyValue.toFixed(2)}%`;

          if (fromAir - outAir === 0) {
            shouldDisableSubmit = true;
          }
        }
      });

      setEfficiency(newEfficiency);
      setDisableSubmit(shouldDisableSubmit);
    };

    calculateEfficiency();
  }, [airValues]);

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    const parsedData = Object.entries(data)
      .map(([key, value]) => {
        const inputElement = document.getElementById(key);

        if (!inputElement) return null;

        const type = inputElement.getAttribute("data-type");
        const id = inputElement.getAttribute("data-id");

        return {
          type,
          id: id ? Number(id) : null,
          value,
        };
      })
      .filter(Boolean);

    console.log(parsedData);
  };

  const handleAirValueChange = (id: number, field: string, value: number) => {
    setAirValues((prevValues) => ({
      ...prevValues,
      [`${field}_${id}`]: value,
    }));
  };

  return (
    <div className="estate-container">
      <div className="estate-box">
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          {error && <Alert severity="error">{error}</Alert>}
          {estate && (
            <Box sx={{ maxHeight: "60vh", overflowY: "auto", width: "400px" }}>
              <SimpleTreeView>
                {estate.addresses.map((address) => (
                  <TreeItem
                    key={address.id}
                    itemId={address.id.toString()}
                    label={
                      <div className={classes.listItem}>
                        <Typography>{address.name}</Typography>
                        {address.allMeasurePointsHaveReadings && (
                          <CheckCircle color="success" />
                        )}
                      </div>
                    }
                  >
                    {address.measurePoints.map((measurePoint) => (
                      <TreeItem
                        key={measurePoint.id}
                        itemId={measurePoint.id.toString()}
                        label={
                          <div
                            className={`${classes.listItem} ${classes.childListItem}`}
                          >
                            <Typography>
                              {measurePoint.name ||
                                measurePoint.measurePointNumbers.join(
                                  ", "
                                )}{" "}
                              {measurePoint.type
                                ? `(${measurePoint.type})`
                                : ""}
                            </Typography>
                            {measurePoint.allCountersHaveReadings && (
                              <CheckCircle color="success" />
                            )}
                          </div>
                        }
                      >
                        {measurePoint.counters.map((counter) => (
                          <TreeItem
                            key={counter.id}
                            itemId={counter.id.toString()}
                            label={
                              <div
                                className={`${classes.listItem} ${classes.grandChildListItem}`}
                              >
                                <div className={classes.multiValueWrapper}>
                                  <div style={{ padding: "0.1rem" }}>
                                    <Typography>
                                      {counter.name.length > 0 ||
                                      counter.counterNumbers.length > 0 ? (
                                        <div>
                                          {counter.name} (
                                          {counter.counterNumbers.join(", ")})
                                        </div>
                                      ) : (
                                        <div>SAVEUP-ID: {counter.id}</div>
                                      )}
                                    </Typography>
                                  </div>
                                  {counter.isHeatExchanger ? (
                                    <div className={classes.multiValueWrapper}>
                                      <TextField
                                        label="Från-luft"
                                        type="number"
                                        inputProps={{ step: "0.01" }}
                                        className="reading-number multi-value-input from-air"
                                        {...register(`from_air_${counter.id}`)}
                                        id={`from_air_val_${counter.id}`}
                                        onChange={(e) =>
                                          handleAirValueChange(
                                            counter.id,
                                            "from_air",
                                            parseFloat(e.target.value)
                                          )
                                        }
                                        onClick={(e) => e.stopPropagation()}
                                        onFocus={(e) => e.stopPropagation()}
                                        data-type="from_air"
                                        data-id={counter.id}
                                      />
                                      <TextField
                                        label="Av-luft"
                                        type="number"
                                        inputProps={{ step: "0.01" }}
                                        className="reading-number multi-value-input off-air"
                                        {...register(`off_air_${counter.id}`)}
                                        id={`off_air_val_${counter.id}`}
                                        onChange={(e) =>
                                          handleAirValueChange(
                                            counter.id,
                                            "off_air",
                                            parseFloat(e.target.value)
                                          )
                                        }
                                        onClick={(e) => e.stopPropagation()}
                                        onFocus={(e) => e.stopPropagation()}
                                        data-type="off_air"
                                        data-id={counter.id}
                                      />
                                      <TextField
                                        label="Ute-luft"
                                        type="number"
                                        inputProps={{ step: "0.01" }}
                                        className="reading-number multi-value-input out-air"
                                        {...register(`out_air_${counter.id}`)}
                                        id={`out_air_val_${counter.id}`}
                                        onChange={(e) =>
                                          handleAirValueChange(
                                            counter.id,
                                            "out_air",
                                            parseFloat(e.target.value)
                                          )
                                        }
                                        onClick={(e) => e.stopPropagation()}
                                        onFocus={(e) => e.stopPropagation()}
                                        data-type="out_air"
                                        data-id={counter.id}
                                      />
                                      <TextField
                                        label="Verkningsgrad"
                                        type="text"
                                        className={`reading-number ${
                                          airValues[`from_air_${counter.id}`] -
                                            airValues[
                                              `out_air_${counter.id}`
                                            ] ===
                                          0
                                            ? classes.errorTextField
                                            : ""
                                        }`}
                                        style={{ maxWidth: 110 }}
                                        name={`efficiency_${counter.id}`}
                                        id={`efficiency_${counter.id}`}
                                        value={efficiency[counter.id] || "0%"}
                                        InputProps={{ readOnly: true }}
                                        onClick={(e) => e.stopPropagation()}
                                        onFocus={(e) => e.stopPropagation()}
                                        data-type="efficiency"
                                        data-id={counter.id}
                                      />
                                      <input
                                        type="hidden"
                                        id={`isHeatExchanger_${counter.id}`}
                                        name={`isHeatExchanger_${counter.id}`}
                                        value={counter.isHeatExchanger.toString()}
                                      />
                                    </div>
                                  ) : (
                                    <TextField
                                      type="number"
                                      inputProps={{ step: "0.01" }}
                                      className="form-control reading-number"
                                      {...register(`val_${counter.id}`)}
                                      id={`val_${counter.id}`}
                                      onClick={(e) => e.stopPropagation()}
                                      onFocus={(e) => e.stopPropagation()}
                                      data-type="val"
                                      data-id={counter.id}
                                    />
                                  )}
                                  <div style={{ padding: "0.1rem" }}>
                                    {counter.lastReading > 0 && (
                                      <Typography
                                        fontSize={"small"}
                                        variant="subtitle2"
                                      >
                                        Senast: {counter.lastReading}
                                        {counter.lastReadingDate &&
                                          ` ${new Date(
                                            counter.lastReadingDate
                                          ).toLocaleString()}`}
                                        {counter.hasReadingThisPeriod && (
                                          <CheckCircle color="success" />
                                        )}
                                      </Typography>
                                    )}
                                    {counter.estimatedReading > 0 && (
                                      <Typography
                                        fontSize={"small"}
                                        variant="subtitle2"
                                      >
                                        Beräknat: {counter.estimatedReading}
                                      </Typography>
                                    )}
                                  </div>
                                </div>
                              </div>
                            }
                          />
                        ))}
                      </TreeItem>
                    ))}
                  </TreeItem>
                ))}
              </SimpleTreeView>
            </Box>
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className="button-primary"
            fullWidth
            disabled={loading || disableSubmit}
          >
            Spara
          </Button>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={() => navigate("/estates")}
          >
            Tillbaka
          </Button>
        </form>
      </div>
    </div>
  );
};

export default EstateView;
