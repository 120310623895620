import { AxiosResponse } from "axios";
import { CounterResetParameter, Estate, Login } from "../interfaces/interfaces";
import saveupClient, { handleResponse } from "./saveupClient";

async function login(data: Login) {
    return await saveupClient.get<AxiosResponse>(`/user/login?username=${data.username}&password=${data.password}`);
};
async function getUserEstates() {
    return await saveupClient.get<Estate[]>(`/user/estates`).then(handleResponse);
};
async function getEstate(estateId: number) {
    return await saveupClient.get<Estate>(`/estate/${estateId}/Full?all=false`).then(handleResponse);
}
async function searchEstates(keyword: string) {
    const result = await saveupClient.post<Estate[]>(`/estate/search?keyword=${keyword}`).then(handleResponse);
    return result;
}
async function saveCounterValue(counterId: number, date: Date, value: number, override: boolean, counterReset: CounterResetParameter) {
    return await saveupClient.post<AxiosResponse>(`/counter/value/save`, { counterId, date, value, override, counterReset })
}
const saveupService = {
    login,
    getUserEstates,
    getEstate,
    searchEstates,
    saveCounterValue
};
export default saveupService;