import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import store from "./redux/store";
import LoginForm from "./components/LoginForm";
import Estates from "./components/Estates";
import EstateView from "./components/EstateView";
import PrivateRoutes from "./components/PrivateRoutes";
import SaveCounterValue from './components/SaveCounterValue';
import background from './img/background.jpg';
import Layout from "./components/Layout";

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <Router>
        <Layout>
        <Routes>
          <Route path="/" element={<LoginForm />} />
          <Route path="/login" element={<LoginForm />} />
          <Route element={<PrivateRoutes />}>
            <Route path="/estates" element={<Estates />} />
            <Route path="/estate/:estateId" element={<EstateView />} />
            <Route path="/saveCounterValue" element={<SaveCounterValue />} />
          </Route>
        </Routes>
        </Layout>
      </Router>
    </Provider>
  );
};

export default App;
