import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, SubmitHandler } from 'react-hook-form';
import { login } from '../redux/authSlice';
import { TextField, Button, Typography, Alert, Box } from '@mui/material';
import { RootState, AppDispatch } from '../redux/store';
import { useNavigate } from 'react-router-dom';
import './style/custom.css'; // Import the custom CSS file
import { Cloud } from '@mui/icons-material';

interface IFormInput {
  username: string;
  password: string;
}

const LoginForm: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { loading, error } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm<IFormInput>();

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    dispatch(login(data)).then((action) => {
      if (login.fulfilled.match(action)) {
        navigate('/estates');
      }
    });
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <Box display="flex" justifyContent="center" className="image-logo">
          <img src="img/saveup.logo.svg" alt="SaveUp Logo" className="logo" />
        </Box>
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
          <TextField
            label="Användarnamn"
            variant="outlined"
            fullWidth
            {...register('username', { required: true })}
          />
          <TextField
            label="Lösenord"
            variant="outlined"
            type="password"
            fullWidth
            {...register('password', { required: true })}
          />
          <Button
            type="submit"
            variant="contained"
            fullWidth
            disabled={loading}
            className="button-primary"
          >
            Logga in
          </Button>
          <Button            
            variant="contained"
            fullWidth
            disabled={loading}
            className="button-cloud"
            style={{backgroundColor: '#007FFF', color: 'white'}}
          >
            <Cloud />&nbsp;Logga in med Microsoft Entra ID
          </Button>
          {error && <Alert severity="error">Felaktigt användarnamn eller lösenord</Alert>}
        </form>
      </div>
    </div>
  );
};

export default LoginForm;