import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, SubmitHandler } from "react-hook-form";
import { saveCounterValue } from "../redux/counterSlice";
import {
  TextField,
  Button,
  Container,
  Typography,
  Alert,
  Box,
  Collapse,
  List,
  ListItem,
  ListItemText,
  IconButton,
  ListItemButton,
} from "@mui/material";
import { ExpandMore, ExpandLess, CheckCircle } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { RootState, AppDispatch } from "../redux/store";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles({
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "1rem",
    marginTop: "2rem",
  },
  logo: {
    width: "150px",
    marginBottom: "1rem",
  },
  listGroup: {
    width: "100%",
  },
  listItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  multiValueWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  errorAir: {
    borderColor: "red",
  },
});

interface IFormInput {
  [key: string]: any;
}

const SaveCounterValue: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch<AppDispatch>();
  const { loading, error, warnings, errors, exists, hasWarning } = useSelector(
    (state: RootState) => state.counter
  );
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm<IFormInput>();
  const [expanded, setExpanded] = useState<string | null>(null);

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    console.log(data);
    dispatch(saveCounterValue(data));
  };

  const handleExpandClick = (id: string) => {
    setExpanded(expanded === id ? null : id);
  };

  return (
    <div className="save-counter-container">
      <div className="save-counter-box">
        <Box display="flex" justifyContent="center">
          <img
            src="img/saveup.logo.svg"
            alt="SaveUp Logo"
            className={classes.logo}
          />
        </Box>
        <Typography variant="h5" className="estate-header">
          {sessionStorage.getItem("estateName")}
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          {error && <Alert severity="error">{error}</Alert>}
          {exists && (
            <div>
              <p>
                Det finns redan ett värde registrerat för detta datum. Vill du
                skriva över?
              </p>
              <Button variant="contained" color="primary" fullWidth>
                Fortsätt
              </Button>
            </div>
          )}
          {hasWarning && (
            <div>
              {warnings.map((warning: any, index: number) => (
                <div key={index}>
                  <ListItem
                    onClick={() => handleExpandClick(warning.counterId)}
                    className={classes.listItem}
                  >
                    <ListItemText primary={warning.counterName || "N/A"} />
                    {expanded === warning.counterId ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </ListItem>
                  <Collapse
                    in={expanded === warning.counterId}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List
                      component="div"
                      disablePadding
                      className={classes.listGroup}
                    >
                      <ListItem className={classes.listItem}>
                        <ListItemText
                          primary={`Räkneverk: ${warning.counterNumbers.join(
                            ", "
                          )} / ${warning.counterName}`}
                          secondary={warning.counterType}
                        />
                        {warning.hasReadingThisPeriod && (
                          <CheckCircle color="success" />
                        )}
                      </ListItem>
                      <TextField
                        label="Kommentar"
                        variant="outlined"
                        fullWidth
                        {...register(`comment_${warning.counterId}`)}
                      />
                      <TextField
                        label="Tag"
                        variant="outlined"
                        fullWidth
                        {...register(`tag_${warning.counterId}`)}
                      />
                    </List>
                  </Collapse>
                </div>
              ))}
            </div>
          )}
          {errors.length > 0 && (
            <div>
              <p>
                <strong>Ett eller flera mätvärden kunde inte sparas!</strong>
              </p>
              {errors.map((error: any, index: number) => (
                <p key={index}>
                  <strong>ID: {error.data.CounterId}</strong>
                  <br />
                  {error.message}
                </p>
              ))}
            </div>
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={loading}
          >
            Spara
          </Button>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={() => navigate("/estates")}
          >
            Tillbaka
          </Button>
        </form>
      </div>
    </div>
  );
};

export default SaveCounterValue;
