// counterSlice.ts
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import saveupService from '../api/saveupService';
import { CounterResetParameter } from '../interfaces/interfaces';
import { handleResponse } from '../api/saveupClient';

interface CounterState {
  data: any | null;
  loading: boolean;
  error: string | null;
  warnings: any[];
  errors: any[];
  exists: boolean;
  hasWarning: boolean;
}

const initialState: CounterState = {
  data: null,
  loading: false,
  error: null,
  warnings: [],
  errors: [],
  exists: false,
  hasWarning: false,
};

export const saveCounterValue = createAsyncThunk(
  'counter/saveCounterValue',
  async (postData: any, { rejectWithValue }) => {
    try {        
      const response = await saveupService.saveCounterValue(postData.counterId, postData.date, postData.value, postData.override, { turned: postData.turned, changed: postData.changed} );
      if (response.status == 200) {
        return handleResponse(response);
      } else {
        return rejectWithValue(response.statusText);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const counterSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(saveCounterValue.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveCounterValue.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(saveCounterValue.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default counterSlice.reducer;